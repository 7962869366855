import 'bootstrap'
import 'popper.js'
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import React from 'react';
import ReactDOM from 'react-dom';

import '../styles/index.scss';
import CalendarView from './calendarView'

import {
  API_KEY, CLIENT_ID,
  DISCOVERY_DOCS,
  SCOPE,
  PAGESIZE,
} from './clientConfig'

ReactDOM.render(
  <CalendarView persist={false} apiKey={API_KEY} clientId={CLIENT_ID} discoveryDocs={DISCOVERY_DOCS} scope={SCOPE} pagesize={PAGESIZE} />,
  document.getElementById('calendar-view-wrapper')
);

library.add(faTrash, faCheck, faTimes, faSignInAlt, faSignOutAlt);
dom.watch();
