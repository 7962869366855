import PropTypes from 'prop-types'
import React from 'react'

import Event from './event'

const Events = (props) => {
  const { current, pagesize, events } = props
  const endItem = current * pagesize - 1
  const startItem = endItem - (pagesize - 1)
  return (
    <>
      {events.map((evt, index) => (
        (startItem <= index && index <= endItem)
          ? <Event key={evt.id} evt={evt} />
          : ''
      ))}
    </>
  )
}

Events.propTypes = {
  current: PropTypes.number,
  pagesize: PropTypes.number,
  events: PropTypes.array,
}

export default Events
