import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({
  header,
  type,
  message,
  code,
  details,
}) => (
  <div className="msg-wrapper">
    <div className="container">
      <div className={`alert alert-${type}`} role="alert">
        <h4 className="alert-heading">{header}</h4>
        <p>
          {(code && message)
            ? (
              <span>
                Fehlercoe:
                {' - '}
                {code}
                {' - '}
                {message}
                <br />
                {JSON.stringify(details, null, 2)}
              </span>
            )
            : message}
        </p>
      </div>
    </div>
  </div>
)

Alert.propTypes = {
  header: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  code: PropTypes.number,
  details: PropTypes.array,
}

export default Alert
