import PropTypes from 'prop-types'
import React from 'react'

const printShortDate = (dt) => dt.format('L')

const Header = (props) => {
  const { title, day } = props
  return (
    <div className="d-flex justify-content-between align-items-end m-3 pb-3 display-2 b-solid-white">
      <div>
        {title}
      </div>
      <div>
        {printShortDate(day)}
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  day: PropTypes.object,
}

export default Header
