import PropTypes from 'prop-types'
import React from 'react'

const PageIndent = (props) => {
  const p = []
  const { current, total } = props
  for (let i = 1; i <= total; i += 1) {
    p.push(i)
  }
  return (
    <div className="d-flex justify-content-between align-items-end m-3 pagetab-wrapper">
      {p.map((page) => (
        <span className={(page === current) ? 'pagetab pt-50 current' : 'pagetab pt-50'}>&nbsp;</span>
      ))}
    </div>
  )
}

PageIndent.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
}

export default PageIndent
