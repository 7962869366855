/* eslint-disable no-undef */
import jQuery from 'jquery'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react';

import Alert from './alert'
import Header from './header'
import Events from './events'
import PageIndent from './pageIndent'

class CalendarView extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('de')
    this.persist = props.persist
    this.pagesize = props.pagesize
    this.config = {
      apiKey: props.apiKey,
      clientId: props.clientId,
      discoveryDocs: props.discoveryDocs,
      scope: props.scope,
    }
    this.state = {
      authenticated: false,
      currentPage: 1,
      events: [],
      // Init todays date as moment with 00:00:00 Time settings
      today: moment().hour(0).minute(0).second(0).millisecond(0),
      totalPages: -1,
      viewconfig: false,
    }
    this.updateSigninStatus = this.updateSigninStatus.bind(this)
    this.registerGlobalEvents = this.registerGlobalEvents.bind(this)
  }

  componentDidMount() {
    // Load google api code
    gapi.load('client:auth2', () => {
      // Init google client
      gapi.client
        .init({
          ...this.config,
        })
        .then(
          () => {
            // Listen for sign-in state changes.
            gapi.auth2
              .getAuthInstance()
              .isSignedIn.listen(this.updateSigninStatus);

            // Handle the initial sign-in state.
            this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
          },
          (error) => {
            //
            if (error.details) {
              this.setState({
                error: error.details,
              })
            } else if (error.error && error.error.code) {
              this.setState({
                error: error.error,
              })
            } else {
              this.setState({
                error: JSON.stringify(error, null, 2),
              })
            }
          }
        );
    })
    // Register timeview update fn
    setInterval(() => {
      this.tick();
    }, 10000);
    setInterval(() => {
      this.refreshEvents();
    }, 1000 * 60 * 15)
    // Setup button tooltips
    this.activateBsTooltips()
    this.registerGlobalEvents()
  }

  componentDidUpdate() {
    if (this.persist) {
      const { events } = this.state
      sessionStorage.setItem('events', JSON.stringify(events))
    }
  }

  activateBsTooltips() {
    jQuery(() => {
      jQuery('[data-toggle="tooltip"]').tooltip()
    })
  }

  tick() {
    const {
      authenticated,
      events,
      currentPage,
      totalPages,
    } = this.state
    if (authenticated && events.length > 0) {
      this.setState({
        currentPage: (currentPage < totalPages) ? currentPage + 1 : 1,
      })
    }
  }

  refreshEvents() {
    const { authenticated } = this.state
    this.setState({
      today: moment().hour(0).minute(0).second(0).millisecond(0),
    })
    if (authenticated) {
      this.loadEvents()
    }
  }

  registerGlobalEvents() {
    document.addEventListener('keypress', (e) => {
      const { viewconfig } = this.state
      if (e.key === 'k') {
        this.setState({
          viewconfig: !viewconfig,
        })
        this.activateBsTooltips()
      }
    })
  }

  updateSigninStatus(authenticated) {
    this.setState({ authenticated })
    if (authenticated) {
      this.loadEvents()
    }
  }

  loadFromSession() {
    if (this.persist) {
      try {
        const e = JSON.parse(sessionStorage.events)
        this.setState((state) => ({
          events: (e.length > 0) ? e : state.events,
        }))
      } catch (e) {
        sessionStorage.setItem('events', JSON.stringify({}))
      }
    }
  }

  login() {
    gapi.auth2.getAuthInstance().signIn()
  }

  logout() {
    gapi.auth2.getAuthInstance().signOut().then(
      this.resetData()
    )
  }

  loadEvents() {
    const { today } = this.state
    gapi.client.calendar.events
      .list({
        calendarId: 'primary',
        timeMin: today.toISOString(),
        timeMax: moment().hour(23).minute(59).second(59).millisecond(999).toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 100,
        orderBy: 'startTime',
      })
      .then((response) => {
        if (response.result.items.length > 0) {
          const events = response.result.items.map((evt) => ({
            id: evt.id,
            title: evt.summary,
            location: evt.location,
            floor: evt.description,
            start: evt.start.dateTime,
            end: evt.end.dateTime,
          }))
          this.setState({
            events,
            totalPages: Math.floor((events.length + this.pagesize - 1) / this.pagesize),
          })
        }
      })
  }

  resetData() {
    this.setState({
      events: [],
      viewconfig: false,
      error: null,
    })
  }

  render() {
    const {
      authenticated,
      error,
      events,
      today,
      viewconfig,
      currentPage,
      totalPages,
    } = this.state
    return (
      <div>
        {(error)
          ? (
            <Alert
              header="Fehler beim Laden der Tabelle"
              type="danger"
              message={(typeof error === 'object') ? error.message : error}
              code={(error.code) ? error.code : null}
              details={(error.errors) ? error.errors : null}
            />
          )
          : ''}
        <Header title="Termine Spatz 21" day={today} />
        <PageIndent current={currentPage} total={totalPages} />
        <div className="m-3">
          <table className="table table-bordered table-dark">
            <thead>
              <tr className="display-4">
                <th scope="col" className="text-center pl-4 pr-4 w-15">
                  Zeit
                </th>
                <th scope="col" className="pl-4 pr-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <span>
                      Event
                    </span>

                    <div className="d-print-none">
                      {(!authenticated)
                        ? (
                          <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Login mit Google Account"
                            className="btn btn-primary btn-lg"
                            onClick={() => this.login()}
                            type="button"
                          >
                            <i className="fas fa-sign-in-alt"></i>
                          </button>
                        )
                        : ''}

                      {(authenticated && viewconfig)
                        ? (
                          (
                            <button
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Logout"
                              className="btn btn-secondary btn-lg"
                              onClick={() => this.logout()}
                              type="button"
                            >
                              <i className="fas fa-sign-out-alt"></i>
                            </button>
                          )
                        )
                        : ''}
                    </div>
                  </div>
                </th>
                <th className="text-right pl-4 pr-4">
                  Raum/Etage
                </th>
              </tr>
            </thead>
            <tbody>
              <Events current={currentPage} pagesize={this.pagesize} events={events} />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

CalendarView.propTypes = {
  persist: PropTypes.bool,
  apiKey: PropTypes.string,
  clientId: PropTypes.string,
  discoveryDocs: PropTypes.array,
  scope: PropTypes.string,
  pagesize: PropTypes.number,
}

export default CalendarView;
