/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

const printTime = (dt) => moment(dt).format('HH:mm')

const Event = (props) => {
  const { evt } = props
  return (
    <tr>
      <td className="pl-4 pr-4">
        <div className="display-3 text-center">
          {printTime(evt.start)}
        </div>
        <div style={{ height: '8px' }} className="progress mt-3 d-none">
          <div className="progress-bar bg-secondary" role="progressbar" style={{ width: '50%' }}></div>
        </div>
      </td>
      <td className="pl-4 pr-4 text-nowrap">
        <span className="display-3">
          {evt.title}
        </span>
      </td>
      <td className="pl-4 pr-4 text-right">
        <span className="display-3">
          {evt.location}
          <br />
          {/* evt.floor */}
        </span>
      </td>
    </tr>
  )
}

Event.propTypes = {
  evt: PropTypes.object,
}

export default Event
